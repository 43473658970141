import React, { Component } from 'react';
import './Contact.css';

export default class NameForm extends Component {
    constructor(props) {
        super(props);
        this.state = {name: '', email:'', msg:''};
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangeEmail = this.handleChangeEmail.bind(this);
        this.handleChangeMsg = this.handleChangeMsg.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangeName(event) {
        this.setState({name: event.target.value});
    }

    handleChangeEmail(event) {
        this.setState({email: event.target.value});
    }

    handleChangeMsg(event) {
        this.setState({msg: event.target.value});
    }

    handleSubmit(event) {
        event.preventDefault();
        const {name, email, msg} = this.state;

        fetch('https://api.github.com/gists', {
            method: 'post',
            body: JSON.stringify({name, email, msg})
        }).then(function(response) {
            return response.json();
        }).then(function(data) {

        });

    }

    render() {

        const {msg, name, email} = this.state;

        return (
            <form onSubmit={this.handleSubmit}>
                <ul className="wrapper">
                    <li className="form-row">
                        <input type="text" id="name" value={name} onChange={this.handleChangeName} placeholder="Name"/>
                    </li>
                    <li className="form-row">
                        <input type="email" id="email" value={email} onChange={this.handleChangeEmail} placeholder="Email"/>
                    </li>
                    <li className="form-row">
                        <textarea value={msg} onChange={this.handleChangeMsg} placeholder="Message"/>
                    </li>
                    <li className="form-row">
                        <button type="submit">Submit</button>
                    </li>
                </ul>
            </form>
        );
    }
}