import React, { Component } from 'react';
import logo from './icon.svg';
import './App.css';
import Contact from './Contact';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {showConnect: false};

    this.handleChange = this.handleChange.bind(this);

  }

  handleChange() {
    this.setState({showConnect: true});
  }

  render() {
    const {showConnect} = this.state;
    return (
      <div className="App">
        {!showConnect && <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Avenue Communications
          </p>
          <a className="App-link" href={"mailto:contact@avenue.fi"}>
            Contact
          </a>

        </header>
        }
      </div>
    );
  }
}

export default App;
